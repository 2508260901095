import React, {useState, useEffect} from 'react';
import MobIcon from '../../../assets/icons/MobIcon.svg';
import {Tooltip , Popover} from 'antd';
import './styles.css';
import QRCode from '../../../pages/Auth/components/DownloadAppComponent/QRCode';
import AppleButton from '../../../pages/Auth/components/DownloadAppComponent/AppleButton';
import AndroidButton from '../../../pages/Auth/components/DownloadAppComponent/AndroidButton';

const Subheader = () => {
	const [visible, setVisible] = useState(false);

	const handleClick = () => {
		setVisible((prev) => !prev);
	};
	const handleClickOutside = (event) => {
		if (visible) {
			setVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [visible]);

	return (
		<div>
			<div className="fixed-header">
				<div className="sg-text">Get more with the SkillGigs app!</div>
				<div>
					<Popover
						overlayClassName="sg-tooltip"
						title={
							<div className="tooltip-div">
								<div style={{width: '40%' ,paddingTop:"4px"}}>
									<QRCode width={'130px'} height={'130px'} />
								</div>
								<div style={{width: '75%'}}>
									<div className="SGtext">Download the SkillGigs App</div>
									<p className="experience-text">
										Enjoy the enhanced experience with the SkillGigs app.
									</p>
									<p className="experience-text">
										Scan the QR code or download from
									</p>
									<div className="SG-icons">
										<AppleButton width={'114px'} height={'33px'} />
										<div style={{paddingLeft:"9px"}}><AndroidButton width={'114px'} height={'33px'} /></div>
									</div>
								</div>
							</div>
						}
						placement="bottomRight"
						mouseEnterDelay={0.5}
						visible={visible}
						onClick={(e) => e.stopPropagation()}
						overlayInnerStyle={{backgroundColor: 'white', color: 'black', borderRadius:"8px"}}
						color="white"
						arrowPointAtCenter
					>
						<button className="btn-clr" onClick={handleClick}>
							<img src={MobIcon}  alt='mobIcon'/>
							<span className="app-text">Download App</span>
						</button>
					</Popover>
				</div>
			</div>
		</div>
	);
};

export default Subheader;
