import React, { useEffect, useState } from "react";
import SocialOptions from "./SocialOptions";
import SignUp from "./SignUp";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import ReactGA from "react-ga4";

import Login from "./Login";
import "./SocialOptions.css";

// Antd CSS
import "antd/dist/antd.css";
import {
  checkEmailFlow,
  isModalVisibleAuth,
  signUpLoginUpdate,
  userClicked,
} from "../../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import {
  APP_NAME,
  EVENTS,
  PAGE,
  PAGE_TYPE,
} from "../../../constants/userTrackingConstants";
import DownloadApp from "./DownloadAppComponent/DownloadApp";

function SignUpLogin({ employerTitle, isStatic = false, isSignup = false, isFbButton = true }) {
  const dispatch = useDispatch();
  const EmpName = useLocation()?.state?.EmpName;
  const [signinClick, setSigninClick] = useState(false);
  const history = useHistory();
  const initialPath = useLocation().pathname.split("/")[1];
  const subRout = useLocation().pathname.split("/")?.[2];
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  // const [employerTitleState, setEmployerTitleState] = useState(
  //   queryParameters.get("empTitle") || employerTitle
  // );

  const getEmployerByQuery = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    return queryParameters.get("empTitle");
  };

  const isEmployerRoute = () => {
    if (initialPath === "employer") {
      return subRout;
    } else return getEmployerByQuery() || EmpName || employerTitle;
  };

  // utm_source, utm_campaign, utm_medium, utm_term
  const utm_source = queryParameters.get("utm_source");
  const utm_campaign = queryParameters.get("utm_campaign");
  const utm_medium = queryParameters.get("utm_medium");
  const utm_term = queryParameters.get("utm_term");

  useEffect(() => {
    localStorage.setItem("utm_source", utm_source);
    localStorage.setItem("utm_campaign", utm_campaign);
    localStorage.setItem("utm_medium", utm_medium);
    localStorage.setItem("utm_term", utm_term);
    if (isStatic) dispatch(signUpLoginUpdate(isSignup));

    // setEmployerTitleState(queryParameters.get("empTitle") || employerTitle);
  }, []);

  const emailForward = useSelector((state) => state.authReducer.emailCheck);
  const signUpLogin = useSelector((state) => state.authReducer.signUpLogin);
  const getEmployerQuery = (emp) => {
    return `empTitle=${emp}`;
  };

  const switchToLoginSignUp = () => {
    ReactGA.event({
      category: "User Interaction",
      action:
        (isStatic ? PAGE.STATIC : "") +
        " " +
        (signUpLogin ? EVENTS.SIGN_IN_TEXT_CLICK : EVENTS.SIGN_UP_TEXT_CLICK),
      label: "Signup Button",
      page_type: isStatic ? PAGE.STATIC : PAGE.MODAL,
      type: isStatic ? PAGE.STATIC : PAGE.MODAL,
    });

    dispatch(
      userClicked({
        uuid: uuid,
        event: signUpLogin
          ? EVENTS.SIGN_IN_TEXT_CLICK
          : EVENTS.SIGN_UP_TEXT_CLICK,
        pageType: signUpLogin ? PAGE_TYPE.SIGNUP : PAGE_TYPE.SIGNIN,
        page: isStatic ? PAGE.STATIC : PAGE.MODAL,
        appName: APP_NAME.TALENT,
        url: window?.location?.href,
      })
    );
    dispatch(checkEmailFlow(false));
    dispatch(signUpLoginUpdate(!signUpLogin));
    setSigninClick(true);
    if (isStatic) {
      if (isSignup) {
        history.push("/signin");
        dispatch(signUpLoginUpdate(false));
      } else {
        history.push("/joinus");
        dispatch(signUpLoginUpdate(true));
      }
    } else if (initialPath == "signup") {
      if (employerTitle)
        history.push(`/login?${getEmployerQuery(employerTitle)}`);
      else history.push("/login");
    } else if (initialPath == "login") {
      if (employerTitle)
        history.push(`/signup?${getEmployerQuery(employerTitle)}`);
      else history.push("/signup");
    }
    setTimeout(() => {
      dispatch(isModalVisibleAuth(true));
    }, 500);
  };

  if (initialPath == "login") {
    dispatch(signUpLoginUpdate(false));
  }
  useEffect(() => {
    localStorage.removeItem('showAlert');
  }, [])
  
  return (
    <div className="authorization-sign-in">
      <div className="sub-div">
        <div className="sg-div">
         <DownloadApp/>
        </div>

        <div className="sg-div">
          <h1 className="authorization-sign-in-top-heading">
            {signUpLogin
              ? `Join ${isEmployerRoute()
                ? isEmployerRoute() || employerTitle
                : "SkillGigs"
              }`
              : `Sign in to ${isEmployerRoute()
                ? isEmployerRoute() || employerTitle
                : "SkillGigs"
              }`}
          </h1>
          {
            // !emailForward && (
            <>
              <div>
                {/* <h1 className="authorization-sign-in-top-heading">Join Skillgigs</h1> */}
                <SocialOptions
                  isStatic={isStatic}
                  signUpLogin={signUpLogin}
                  employerTitle={
                    isEmployerRoute() ? isEmployerRoute() || employerTitle : null
                  }
                  isFbButton={isFbButton}
                />
                <div className="divider-main">
                  <h5 className="divider">OR</h5>
                </div>
              </div>
            </>
            // )
          }
          {signUpLogin || isSignup ? (
            <>
              <SignUp
                isStatic={isStatic}
                signinClick={signinClick}
                employerTitle={
                  isEmployerRoute() ? isEmployerRoute() || employerTitle : null
                }
              />
            </>
          ) : (
            <Login
              isStatic={isStatic}
              employerTitle={
                isEmployerRoute() ? isEmployerRoute() || employerTitle : null
              }
            />
          )}
          <div className="auth-divider-line"></div>
          <div className="account-allready-section">
            {signUpLogin ? (
              <>
                Already have an account?
                <span
                  className={`${isStatic ? "static-sign-in" : ""} account-sign-in`}
                  onClick={switchToLoginSignUp}
                >
                  {`Sign in`}
                </span>
              </>
            ) : (
              <>
                Not a member yet?
                <span
                  className={`${isStatic ? "static-sign-in" : ""} account-sign-in`}
                  onClick={switchToLoginSignUp}
                >
                  {`Sign up`}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpLogin;

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
