import React from 'react';
import QRCode from './QRCode';
import AppleButton from './AppleButton';
import AndroidButton from './AndroidButton';

const DownloadApp = () => {
	return (
		<>
			<p className="sg-heading">
				Download the <br /> SkillGigs app
			</p>
			<p className="sg-sub-heading">
				Enjoy an enhanced experience with the <br /> SkillGigs app
			</p>
			<div style={{textAlign: 'center'}}>
				<QRCode width={'196px'} height={'196px'}/>
                <p className="scan-text">Scan QR code to get the app</p>
			</div>
			<p className="download-text">Download app from</p>
			<div className="sg-icons">
				<AppleButton />
				<AndroidButton />
			</div>
		</>
	);
};

export default DownloadApp;
