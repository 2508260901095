import React from 'react';
import SgAndroidIcon from '../../../../assets/icons/SgAndroidIcon.svg';

const AndroidButton = ({width,height}) => {
	return (
		<>
			<a
				href={
					'https://play.google.com/store/apps/details?id=com.skillgigsmarketplace'
				}
				target="_blank" rel="noreferrer"
			>
				<img src={SgAndroidIcon} alt="SgAndroidIcon" style={{width, height}}/>
			</a>
		</>
	);
};

export default AndroidButton;
