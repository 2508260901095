import {React} from 'react';
import { Alert, Button } from "antd";
import SGIcon from '../../assets/icons/SGIcon.svg'
import './styles.css'

const DownloadAppAlert = () => {
    const showAlert = localStorage.getItem('showAlert') === null;
    
	const handleRedirect = () => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		const appSchemeUrl = 'skillgigs://'; 

		const openAppOrStore = (storeUrl) => {
			window.location.href = appSchemeUrl;
			setTimeout(() => {
				window.location.href = storeUrl;
			}, 500);
		};
	
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			openAppOrStore('https://onelink.to/22y433');
		} else if (/android/i.test(userAgent)) {
			openAppOrStore('https://onelink.to/22y433');
		} else {
			console.log('Unsupported platform');
		}
	};
	
	return (
		<div style={{width: '100%', position: 'fixed', bottom: '0%', zIndex:"1000"}}>
			{showAlert ? <Alert
				description={
					<div style={{display: 'flex'}}>
						<div style={{paddingTop: '16px'}}>
							<img src={SGIcon} />
						</div>
						<div style={{padding: '7px 0px 0px 15px'}}>
							Get more with the SkillGigs app
						</div>
						<div style={{padding: '8px 0px 0px 13px'}}>
							<Button
								style={{
									backgroundColor: '#118936',
									border: '1px solid #118936',
									color: 'white',
								}}
                                onClick={handleRedirect}
							>
								Open
							</Button>
						</div>
					</div>
				}
				type="info"
				closable
				className="mobile-alert"
				style={{backgroundColor: '#0F1E2B', color: 'white', height: '85px'}}
                onClose={()=>{localStorage.setItem('showAlert', false)}}
			/> : ""}
		</div>
	);
};

export default DownloadAppAlert;
