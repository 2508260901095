import React from 'react';
import SgQrCode from '../../../../assets/img/SgQrCode.png';

const QRCode = ({width,height}) => {
	return (
		<>
			<img src={SgQrCode} alt="SgQrCode" width={width} height={height}/>
		</>
	);
};

export default QRCode;
