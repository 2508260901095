import React from 'react';
import SgAppleIcon from '../../../../assets/icons/SgAppleIcon.svg';

const AppleButton = ({width,height}) => {
	return (
		<>
			<a
				href={'https://apps.apple.com/us/app/skillgigs/id6499452606'}
				target="_blank" rel="noreferrer" 
			>
				<img src={SgAppleIcon} alt="SgAppleIcon" style={{width, height}}/>
			</a>
		</>
	);
};

export default AppleButton;
