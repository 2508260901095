import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Form, Input, notification } from "antd";
import "./email.css";
import { Auth } from "../../config/aws-exports";
import { GetQueryParams } from "./../../assets/helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../Loader/Loader";
import { alertNotification } from "../notifications/alertNotification";

const EmailInvitation = () => {
  const email = GetQueryParams()?.["candidate-email"] || "";
  const name = GetQueryParams()?.["talent-name"] || "";

  const OTP_LENGTH = 6;

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [greenCheckPassword, setGreenCheckPassword] = useState(false);
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const history = useHistory();

  const handleChange = (otp) => {
    setOtp(otp);
    setOtpError("");
  };

  const validatePassword = (e) => {
    const password = e.target.value;
    const isValid = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(
      password
    );
    setPassword(password);
    setGreenCheckPassword(isValid);
    setShowCheckPassword(!isValid && password.length > 0);
  };

  const showNotification = (type = "error", message, duration = 4) => {
    alertNotification(type, message, null, null, message, duration);
  };

  const sendCode = async (email) => {
    try {
      Auth.forgotPassword(email)
        .then((data) => {})
        .catch((err) => {
          console.warn("err", err);
          showNotification("error", err?.message || "Error sending OTP!");
        });
    } catch (error) {
      console.warn("Error sending otp", error);
      showNotification("error", "Error sending OTP!");
    } finally {
      setLoader(false);
    }
  };


  const resetPassword = async () => {
    try {
      if (otpError || showCheckPassword) {
        showNotification("error", "Invalid");
        return;
      }
      if (otp.length !== OTP_LENGTH) return setOtpError("Enter Otp");
      if (password == "") {
        setShowCheckPassword(true);
        showNotification("error", "Invalid");
        return;
      }
      setLoading(true);

      const response = await Auth.forgotPasswordSubmit(email, otp, password);
      if (response === "SUCCESS") await onLogin({ email, password });
      else {
        setErrorMessage("This verification code didn't work. Try again");
        showNotification(
          "error",
          response?.message || "This verification code didn't work. Try again"
        );
      }
    } catch (error) {
      console.warn("Error", error);
      showNotification(
        "error",
        "This verification code didn't work. Try again"
      );
    } finally {
      setLoading(false);
    }
  };

  const onLogin = async ({ email, password }) => {
    try {
      setLoading(true);
      setLoader(true);
      await Auth.signIn({ username: email, password });
      await Auth.currentSession();
      setLoading(false);
      history.push("/auth");
    } catch (error) {
      console.warn("error", error);
    } finally {
      setLoading(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    sendCode(email);
  }, []);

  if (loader) return <Loader />;
  return (
    <div className="main-text">
      <div className="welcome-text">
        <div className="texts">
          Welcome {name || ""} to
          <span className="Tittle-text"> SkillGigs!</span>
          <span className="sub-tittle-text">
            {" "}
            Set your password to continue
          </span>
        </div>
        <div className="main-sub">
          <div className="verification-text">
            <Form>
              Verification code
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={OTP_LENGTH}
                isInputNum
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderColor: otpError ? "red" : "",
                    }}
                  />
                )}
                containerStyle="otp-container"
                inputStyle="otp-input"
              />
              {!otpError && (
                <div className="validation-text">
                  Enter the verification code sent to your email
                </div>
              )}
              {otpError && (
                <div className="otp-error" style={{ color: "red" }}>
                  {otpError}
                </div>
              )}
              <Form.Item
                name="password"
                extra={
                  <div
                    className={`passwordHelp ${
                      greenCheckPassword
                        ? showCheckPassword
                          ? "displayNoneHelpPass"
                          : null
                        : showCheckPassword
                        ? "notValidColor"
                        : null
                    }`}
                  >
                    Password must be at least 8 characters, including numbers,
                    uppercase and lowercase letters.
                  </div>
                }
                rules={[
                  { pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/ },
                  { required: true },
                ]}
                help={false}
              >
                <Input.Password
                  autoComplete="new-password"
                  onChange={validatePassword}
                  placeholder="Choose a Password"
                  className={`passwordSignUp ${
                    greenCheckPassword
                      ? "validInputCSS"
                      : showCheckPassword
                      ? "notValidInput"
                      : null
                  }`}
                  iconRender={(visible) =>
                    visible ? (
                      <span style={{ color: "#4054b2", fontSize: "12px" }}>
                        Hide
                      </span>
                    ) : (
                      <span style={{ color: "#4054b2", fontSize: "12px" }}>
                        Show
                      </span>
                    )
                  }
                />
              </Form.Item>
              <div className="terms-and-services">
                {`By joining, I agree to Skillgigs`}
                <a className="subTerm" href="/terms">
                  <u>Terms of Services</u>
                </a>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={resetPassword}
                  className="continue-text"
                  // disabled={loading}
                >
                  <div className="continue-btn-text">Continue</div>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailInvitation;
